import React from "react";
import { Box, Button } from "@chakra-ui/react";

export default function Buttons({ selectedBtn, setSelectedBtn }) {
  return (
    <Box
      maxWidth="1320px"
      margin="20px auto"
      display="flex"
      flexWrap="wrap"
      gap={{ base: "5px", md: "20px" }}
      padding="0 20px"
    >
      <Button
        colorScheme="none"
        onClick={() => setSelectedBtn("open")}
        className={selectedBtn === "open" ? "active-btn" : "not-active-btn"}
        width={{ base: "90px", sm: "115px", md: "110px" }}
        fontSize={{ base: "10px", sm: "13px", md: "16px" }}
      >
        Open Tasks
      </Button>
      <Button
        colorScheme="none"
        onClick={() => setSelectedBtn("today")}
        className={selectedBtn === "today" ? "active-btn" : "not-active-btn"}
        width={{ base: "90px", sm: "115px", md: "147px" }}
        fontSize={{ base: "10px", sm: "13px", md: "16px" }}
      >
        Today's Tasks
      </Button>
      <Button
        colorScheme="none"
        onClick={() => setSelectedBtn("closed")}
        className={selectedBtn === "closed" ? "active-btn" : "not-active-btn"}
        width={{ base: "90px", sm: "115px", md: "164px" }}
        fontSize={{ base: "10px", sm: "13px", md: "16px" }}
      >
        Closed Tasks
      </Button>
      <Button
        colorScheme="none"
        onClick={() => setSelectedBtn("all")}
        className={selectedBtn === "all" ? "active-btn" : "not-active-btn"}
        width={{ base: "90px", sm: "115px", md: "110px" }}
        fontSize={{ base: "10px", sm: "13px", md: "16px" }}
      >
        All Tasks
      </Button>
    </Box>
  );
}
