import { useEffect, useRef, useState } from "react";

import {
  addTask,
  getAll,
  getAllCategories,
  getAllTasks,
  getOpenTasks,
} from "./api/api_functions";

import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "./firebase.config";

import AllTasks from "./components/all_tasks/AllTasks";
import Buttons from "./components/buttons/Buttons";
import TodayTasks from "./components/today_tasks/TodayTasks";
import ClosedTasks from "./components/closed_tasks/ClosedTasks";
import SubFolder from "./components/sub-folder/SubFolder";
import Folder from "./components/folder/Folder";

import AWS from "aws-sdk";

import { useForm } from "react-hook-form";

import {
  Box,
  Button,
  FormControl,
  Heading,
  Image,
  Input,
  Spinner,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tasks from "./components/tasks/Tasks";

function App() {
  const [selectedFolder, setSelectedFolder] = useState();
  const [saveSelectedFolder, setSaveSelectedFolder] = useState();
  const [selectedSubFolder, setSelectedSubFolder] = useState();
  const [saveSelectedSubFolder, setSaveSelectedSubFolder] = useState();
  const [fileName, setFileName] = useState({ name: "", loading: false });
  const fileInputRef = useRef(null);
  const [selectedBtn, setSelectedBtn] = useState("open");
  const [tasks, setTasks] = useState([]);
  const [todaysTasks, setTodaysTasks] = useState([]);
  const [error, setError] = useState({
    folderError: "",
    subFolderError: "",
    titleError: "",
  });
  const [categories, setCategories] = useState([]);
  const [response, setResponse] = useState([]);

  // For Image Storing in firebase
  const [imgUrl, setImgUrl] = useState(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const resizeTextArea = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const textAreaRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "image/png") {
      setFileName({ name: file.name, loading: true });

      const storageRef = ref(storage, `images/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (error) => {
          toast.error("Error uploading file");
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImgUrl(downloadURL);
            setFileName({ name: file.name, loading: false });
          });
        }
      );
    } else {
      toast.error("Please upload a valid PNG file");
    }
  };

  const getAllTasks = async () => {
    try {
      const response = await getAll();
      const data = response.value.data;
      setResponse(data);
    } catch (error) { }
  };

  const getTasks = async () => {
    try {
      const task = await getOpenTasks();
      setTasks(task.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getCategories = async () => {
    try {
      const response = await getAllCategories();
      setCategories(response.items);
    } catch (error) {
      console.log(error);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const handleSubmitForm = async (data) => {
    let isValid = true;
    if (!!!watch("taskTitle").trim()) {
      isValid = false;
      setError((prevError) => ({
        ...prevError,
        titleError: "Task Title is required",
      }));
    }

    if (watch("taskTitle").length > 249) {
      isValid = false;
      setError((prevError) => ({
        ...prevError,
        titleError: "Task Title can't be more than 250 characters",
      }));
    }

    if (!selectedFolder) {
      isValid = false;
      setError((prevError) => ({
        ...prevError,
        folderError: "Folder name is required",
      }));
    }

    if (selectedFolder && selectedFolder.label.length > 249) {
      isValid = false;
      setError((prevError) => ({
        ...prevError,
        folderError: "Folder name can't be more than 255 characters",
      }));
    }

    if (!selectedSubFolder) {
      isValid = false;
      setError((prevError) => ({
        ...prevError,
        subFolderError: "Sub Folder name is required",
      }));
    }
    if (selectedSubFolder && selectedSubFolder.length > 249) {
      isValid = false;
      setError((prevError) => ({
        ...prevError,
        subFolderError: "Sub folder name can't be more than 250 characters",
      }));
    }
    if (isValid) {
      try {
        const newTask = {
          name: data.taskTitle?.replace(/\s+/g, " ").trim(),
          categoryId: saveSelectedFolder?.id,
          subCategoryId: saveSelectedSubFolder?.id,
          image: imgUrl,
        };

        const response = await addTask(
          newTask.name,
          newTask.categoryId,
          newTask.subCategoryId,
          newTask.image
        );

        if (response.data.statusCode === 200) {
          setError({
            titleError: "",
            folderError: "",
            subFolderError: "",
          });
          toast.success("Task added successfully");

          // Reset form
          reset();
          setSelectedFolder(null);
          setSaveSelectedFolder(null);
          setSelectedSubFolder(null);
          setSaveSelectedSubFolder(null);
          setImgUrl("");
          setFileName({ name: "", loading: false });
          getTasks();
          getAllTasks();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <Image
        position="absolute"
        top="-15px"
        left="10px"
        width="70px"
        src="/icons/Rangreza-Logo.svg"
        alt="Rangreza Logo"
      />
      <Heading
        as="h1"
        textAlign="center"
        fontWeight="400"
        fontSize="32px"
        margin="20px 0"
      >
        Task Portal
      </Heading>
      <Box
        maxWidth="1320px"
        width="100%"
        padding="30px 24px"
        borderRadius="32px"
        backgroundColor="#F7F7F7"
        margin="0 auto"
      >
        <FormControl
          as="form"
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          gap="24px"
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <Box width="100%">
            <label htmlFor="task-title">
              Task Title
              <Box as="span" color="red">
                *
              </Box>
            </label>
            <Textarea
              ref={textAreaRef}
              minHeight="40px"
              placeholder="Task Title"
              id="task-title"
              {...register("taskTitle")}
              style={{ width: "100%", resize: "none", overflow: "hidden" }}
              onChange={(e) => resizeTextArea(e.target)}
            />
            {!watch("taskTitle")?.trim() && error.titleError && (
              <Box color="red" fontSize="12px" marginTop="5px">
                {error.titleError}
              </Box>
            )}
          </Box>
          <Box
            width="100%"
            display="flex"
            alignItems="flex-end"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexWrap="wrap"
              gap="24px"
              justifyContent="flex-start"
              width={{ base: "100%", md: "80%" }}
            >
              <Box
                display="flex"
                gap="10px"
                alignItems="center"
                minWidth={{ base: "100%", md: "40%" }}
              >
                <Folder
                  setSelectedFolder={setSelectedFolder}
                  selectedFolder={selectedFolder}
                  error={error}
                  setError={setError}
                  getCategories={getCategories}
                  categories={categories}
                  setCategories={setCategories}
                  saveSelectedFolder={saveSelectedFolder}
                  setSaveSelectedFolder={setSaveSelectedFolder}
                />
              </Box>
              <Box
                display="flex"
                gap="10px"
                alignItems="center"
                minWidth={{ base: "100%", md: "40%" }}
              >
                <SubFolder
                  selectedSubFolder={selectedSubFolder}
                  setSelectedSubFolder={setSelectedSubFolder}
                  selectedFolder={selectedFolder}
                  error={error}
                  setError={setError}
                  r={error}
                  ck={handleImageClick}
                  cursor="pointer"
                  saveSelectedFolder={saveSelectedFolder}
                  saveSelectedSubFolder={saveSelectedSubFolder}
                  setSaveSelectedSubFolder={setSaveSelectedSubFolder}
                />
              </Box>
              <Box marginBottom={{ base: "10px", md: "0" }}>
                <label htmlFor="">Attachment</label>
                <Image
                  margin="10px auto 0"
                  src="/icons/upload-img.svg"
                  alt="Upload Image"
                  onClick={handleImageClick}
                  cursor="pointer"
                />
                <Input
                  type="file"
                  id="file-upload"
                  ref={fileInputRef}
                  display="none"
                  onChange={handleFileChange}
                  accept=".png"
                />
                {fileName.loading ? (
                  <Spinner size="xs" marginLeft="26px" marginTop="10px" />
                ) : (
                  <Text mt={2} fontSize="10px">
                    {fileName.name}
                  </Text>
                )}
              </Box>
            </Box>
            <Box width={{ base: "100%", md: "20%" }}>
              <Button
                className="button"
                colorScheme="none"
                isDisabled={fileName.loading}
                width="100%"
                type="submit"
              >
                Save
              </Button>
            </Box>
          </Box>
        </FormControl>
      </Box>
      <Buttons selectedBtn={selectedBtn} setSelectedBtn={setSelectedBtn} />
      {selectedBtn === "all" && (
        <Tasks getAllTasks={getAllTasks} response={response} tasks={tasks} />
      )}
      {selectedBtn === "open" && (
        <AllTasks
          getTasks={getTasks}
          tasks={tasks}
          getCategories={getCategories}
        />
      )}
      {selectedBtn === "today" && (
        <TodayTasks
          getTasks={getTasks}
          todaysTasks={todaysTasks}
          setTodaysTasks={setTodaysTasks}
        />
      )}
      {selectedBtn === "closed" && <ClosedTasks />}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default App;
