import axios from "axios";

const url = "https://tpbe.rangreza.xyz/api/";

// For adding the category
export const addCategory = async (category) => {
  try {
    const BODY = {
      name: category,
    };

    const response = await axios.post(`${url}category/add`, BODY);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Get all Categories

export const getAllCategories = async () => {
  try {
    const response = await axios.get(`${url}category/get_all`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// To add a sub-category

export const addSubCategory = async (subCategory, folderId) => {
  try {
    const BODY = {
      name: subCategory,
      categoryId: folderId,
    };
    const response = await axios.post(`${url}sub_category/add`, BODY);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Get all sub-categories

export const getAllSubCategories = async (selectedFolder) => {
  try {
    const response = await axios.get(
      `${url}category/get_all_sub_categories?categoryId=${selectedFolder?.id}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Add the task

export const addTask = async (task, folderId, subFolderId, img) => {
  try {
    const BODY = {
      name: task,
      categoryId: folderId,
      subCategoryId: subFolderId,
      image: img,
    };
    const response = await axios.post(`${url}task/add`, BODY);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// Get all Open tasks

export const getOpenTasks = async () => {
  try {
    const response = await axios.get(`${url}task/all_task`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Add task in today task

export const todayTask = async (isMark, id, name, image) => {
  try {
    const BODY = {
      taskId: id,
      isMarkedToday: !isMark,
      name,
      image,
    };
    const response = await axios.put(`${url}task/update`, BODY);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// Get all today tasks

export const getTodayTasks = async () => {
  try {
    const response = await axios.get(`${url}task/all_todays_tasks`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Task is done

export const taskDone = async (isCompleted, id, name, image) => {
  try {
    const BODY = {
      taskId: id,
      isCompleted: !isCompleted,
      name,
      image,
    };
    const response = await axios.put(`${url}task/update`, BODY);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// Get all closed tasks

export const getClosedTasks = async () => {
  try {
    const response = await axios.get(`${url}task/all_closed_tasks`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Get all Tasks

export const getAll = async () => {
  try {
    const response = await axios.get(`${url}task/get_all_tasks`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Update folder name
export const updateFolderName = async (folderId, name) => {
  try {
    const BODY = {
      id: folderId,
      name,
    };
    const response = await axios.put(`${url}category/update`, BODY);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// Update subfolder name
export const updateSubFolderName = async (folderId, subFolderId, name) => {
  try {
    const BODY = {
      id: subFolderId,
      name,
      categoryId: folderId,
    };
    const response = await axios.put(`${url}sub_category/update`, BODY);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// Update task name
export const updateTaskName = async (taskId, name, image) => {
  try {
    const BODY = {
      taskId,
      name,
      image,
    };
    const response = await axios.put(`${url}task/update`, BODY);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// Search task

export const searchTask = async (search, flag) => {
  try {
    const response = await axios.get(
      `${url}task/search?name=${search}${flag ? `&flag=${flag}` : ""}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Swap Task

export const taskReorder = async (taskId, newSequenceNumber) => {
  try {
    const response = await axios.get(
      `${url}task/reorder?taskId=${taskId}&newSequenceNumber=${newSequenceNumber}`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
