import React, { useEffect, useState } from "react";

import { addSubCategory, getAllSubCategories } from "../../api/api_functions";

import CreatableSelect from "react-select/creatable";

import { Box, Input, Button, Text } from "@chakra-ui/react";

export default function SubFolder({
  selectedFolder,
  setSelectedFolder,
  selectedSubFolder,
  setSelectedSubFolder,
  error,
  setError,
  categories,
  saveSelectedFolder,
  setSaveSelectedSubFolder,
}) {
  const [subCategories, setSubCategories] = useState([]);

  const getSubCategories = async (folder) => {
    try {
      const response = await getAllSubCategories(folder || saveSelectedFolder);
      // setSubCategories(response.items);
      const sortedSubCategories = response.items.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setSubCategories(sortedSubCategories);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (saveSelectedFolder) {
      getSubCategories();
    }
  }, [saveSelectedFolder]);

  const createOption = (label) => ({
    label,
    id: "NEW",
    categoryId: label,
  });

  const fetchCategory = async (value) => {
    const option = categories.find((c) => c?.name == value);
    setSelectedFolder((prev) => ({ ...prev, id: option?.id }));
    if (option) {
      return option;
    } else {
      setError("No folder selected");
    }
  };

  const handleAddSubFolder = async (newSubFolderName) => {
    if (saveSelectedFolder && saveSelectedFolder?.id !== "NEW") {
      try {
        const newOption = createOption(newSubFolderName);
        setSubCategories((prev) => [...prev, newOption]);
        setSelectedSubFolder(newOption);
        const subCategoryAdd = await addSubCategory(
          newSubFolderName,
          saveSelectedFolder?.id
        );
        setSaveSelectedSubFolder(subCategoryAdd.data);
        await getSubCategories();
      } catch (error) {
        console.log(error);
      }
    } else {
      const newOption = createOption(newSubFolderName);
      console.log("newOption", newOption);
      console.log("SUB CAT 1 : ", subCategories);
      setSubCategories((prev) => [...prev, newOption]);
      setSelectedSubFolder(newOption);
      console.log("selectedFolder", saveSelectedFolder);
      console.log("SUB CAT 2 : ", subCategories);
      const selectedId = await fetchCategory(saveSelectedFolder?.label);
      console.log("selectedId >>>>>>>> ", selectedId);
      await addSubCategory(newSubFolderName, selectedId?.id);
      await getSubCategories(selectedId);
    }
  };

  const subCate = subCategories.map((subCategory) => ({
    id: subCategory.id,
    label: subCategory.name,
    categoryId: subCategory.categoryId,
  }));

  useEffect(() => {
    setSelectedSubFolder(null);
  }, [saveSelectedFolder]);

  return (
    <>
      <Box width="100%">
        <label htmlFor="sub-folder">
          Sub Folder
          <Box as="span" color="red">
            *
          </Box>
        </label>
        <CreatableSelect
          isClearable
          className="select"
          options={subCate}
          placeholder="Select Sub-Folder"
          onChange={(sub) => {
            setSelectedSubFolder(sub);
            setSaveSelectedSubFolder(sub);
          }}
          value={selectedSubFolder}
          onCreateOption={(inputValue) => {
            handleAddSubFolder(inputValue);
          }}
        />
        {!selectedSubFolder && error.subFolderError && (
          <Box color="red" fontSize="12px" marginTop="5px">
            {error.subFolderError}
          </Box>
        )}
      </Box>
    </>
  );
}
