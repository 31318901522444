import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Checkbox,
  Image,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { searchTask } from "../../api/api_functions";

export default function Tasks({ getAllTasks, response, tasks }) {
  const [searchedTasks, setSearchedTasks] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [noTask, setNoTask] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    getAllTasks();
  }, []);

  useEffect(() => {
    getAllTasks();
  }, [tasks]);

  const handleSearch = async (name) => {
    if (name) {
      const response = await searchTask(name);
      if (response.data !== null) {
        setSearchedTasks(response?.data);
        setNoTask(false);
      } else {
        setSearchedTasks([]);
        setNoTask(true);
      }
    } else {
      setSearchedTasks([]);
      setNoTask(false);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);
    if (value === "") {
      setSearchedTasks([]);
      setNoTask(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(searchInput);
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setSelectedImage(null);
  };

  const tasksToDisplay = searchedTasks?.length ? searchedTasks : response;
  return (
    <>
      {response && (
        <>
          <Input
            width={{ base: "90%", md: "50%" }}
            margin="20px auto"
            display="block"
            value={searchInput}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            placeholder="Search tasks..."
            border={"1px solid gray"}
          />
          {!noTask ? (
            <Accordion
              allowMultiple
              padding="0 20px"
              maxWidth="1320px"
              margin="20px auto"
            >
              {tasksToDisplay.map((task, index) => {
                return (
                  <AccordionItem key={index} border="none">
                    <h2>
                      <AccordionButton
                        width="100%"
                        className="folder-bg-color"
                        margin="10px 0"
                        padding="15px"
                      >
                        <Box as="span" flex="1" textAlign="left">
                          {task.categoryName}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    {task.subCategories.map((subCategory, index) => {
                      return (
                        <AccordionPanel
                          overflowX={{ base: "scroll", sm: "auto" }}
                          key={index}
                        >
                          <Accordion
                            allowMultiple
                            width={{ base: "600px", md: "100%" }}
                          >
                            <AccordionItem
                              border="none"
                              width={{ base: "600px", md: "100%" }}
                            >
                              <AccordionButton
                                className="folder-bg-color"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                padding="15px"
                              >
                              <Box
                                width="20%"
                                display="flex"
                                justifyContent="center"
                              >
                                <Text>Done</Text>
                              </Box>
                              <Box
                                width="20%"
                                display="flex"
                                justifyContent="center"
                              >
                                <Text>Today</Text>
                              </Box>
                                <Box
                                  width="40%"
                                  display="flex"
                                  justifyContent="flex-start"
                                >
                                  {/* <Text>{subCategory.subCategoryName}</Text> */}
                                  <Text
                                    overflowX={{ base: "scroll", sm: "auto", md: "auto" }}
                                  >{subCategory.subCategoryName}</Text>
                                </Box>
                                <Box
                                  width="20%"
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <Text>Return</Text>
                                </Box>
                                <Box
                                  width="20%"
                                  display="flex"
                                  justifyContent="flex-end"
                                >
                                  <Text>Attachment</Text>
                                </Box>
                              </AccordionButton>
                              {subCategory.tasks.map((title, index) => {
                                return (
                                  <AccordionPanel
                                    width="100%"
                                    display="flex"
                                    justifyContent="space-between"
                                    key={title.id}
                                    gap="10px"
                                    alignItems="center"
                                  >
                                    <Text fontWeight="600">{index + 1}</Text>
                                    <Box
                                      marginTop="5px"
                                      width="20%"
                                      display="flex"
                                      justifyContent="center"
                                    >
                                      <Checkbox
                                        value={title.isCompleted}
                                        isChecked={title.isCompleted}
                                      />
                                    </Box>
                                    <Box
                                      marginTop="5px"
                                      width="20%"
                                      display="flex"
                                      justifyContent="center"
                                    >
                                      <Checkbox
                                        value={title.isMarkedToday}
                                        isChecked={title.isMarkedToday}
                                      />
                                    </Box>
                                    <Box width="40%">
                                      <Text>{title.name}</Text>
                                    </Box>
                                    <Box
                                      marginTop="5px"
                                      width="20%"
                                      display="flex"
                                      justifyContent="center"
                                    >
                                      <Checkbox
                                        isChecked={
                                          !title.isCompleted &&
                                          !title.isMarkedToday
                                        }
                                      />
                                    </Box>
                                    <Box
                                      marginTop="5px"
                                      width="20%"
                                      display="flex"
                                      justifyContent={{
                                        base: "center",
                                        sm: "flex-end",
                                      }}
                                      marginRight={{ base: "0", sm: "15px" }}
                                    >
                                      {title.image && (
                                        <Image
                                          src={title.image}
                                          width="30px"
                                          height="30px"
                                          borderRadius="50%"
                                          onClick={() =>
                                            handleImageClick(title.image)
                                          }
                                          cursor="pointer"
                                        />
                                      )}
                                    </Box>
                                  </AccordionPanel>
                                );
                              })}
                            </AccordionItem>
                          </Accordion>
                        </AccordionPanel>
                      );
                    })}
                  </AccordionItem>
                );
              })}
            </Accordion>
          ) : (
            <Text textAlign="center" margin="20px 0">
              No Tasks Found
            </Text>
          )}
        </>
      )}

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedImage && <Image src={selectedImage} width="100%" />}
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
