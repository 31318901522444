import React, { useEffect, useState } from "react";
import { addCategory, getAllCategories } from "../../api/api_functions";
import CreatableSelect from "react-select/creatable";
import { Box, Text, Input, Button } from "@chakra-ui/react";

export default function Folder({
  selectedFolder,
  setSelectedFolder,
  error,
  categories,
  setCategories,
  saveSelectedFolder,
  setSaveSelectedFolder,
}) {
  const getCategories = async () => {
    try {
      const response = await getAllCategories();
      // setCategories(response.items);
      const sortedCategories = response.items.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setCategories(sortedCategories);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const createOption = (label) => ({
    label,
    id: "NEW",
  });

  const handleAddFolder = async (newFolderName) => {
    try {
      const newOption = createOption(newFolderName);
      setCategories((prev) => [...prev, newOption]);
      setSelectedFolder(newOption);
      const categoryAdd = await addCategory(newFolderName);
      setSaveSelectedFolder(categoryAdd.data);
      await getCategories();
    } catch (error) {
      console.log(error);
    }
  };

  const cate = categories.map((category) => ({
    id: category.id,
    label: category.name,
  }));
  return (
    <Box width="100%">
      <label htmlFor="folder">
        Folder
        <Box as="span" color="red">
          *
        </Box>
      </label>

      <CreatableSelect
        isClearable
        className="select"
        options={cate}
        placeholder="Select Folder"
        onChange={(folder) => {
          setSelectedFolder(folder);
          setSaveSelectedFolder(folder);
        }}
        value={selectedFolder}
        onCreateOption={(inputValue) => {
          handleAddFolder(inputValue);
        }}
      />
      {!selectedFolder && error.folderError && (
        <Box color="red" fontSize="12px" marginTop="5px">
          {error.folderError}
        </Box>
      )}
    </Box>
  );
}
