import React, { useEffect, useState } from "react";

import {
  getTodayTasks,
  searchTask,
  taskDone,
  todayTask,
} from "../../api/api_functions";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Checkbox,
  Image,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

export default function TodayTasks({ getTasks }) {
  const [taskToday, setTaskToday] = useState([]);
  const [searchedTasks, setSearchedTasks] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [noTask, setNoTask] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const getTodayTask = async () => {
    try {
      const response = await getTodayTasks();
      // console.log(response.data);
      setTaskToday(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReturn = async (isMark, id, name, image) => {
    const response = await todayTask(isMark, id, name, image);
    getTodayTask();
    getTasks();
  };
  const handleDone = async (isCompleted, id, name, image) => {
    const response = await taskDone(isCompleted, id, name, image);
    getTodayTask();
  };

  useEffect(() => {
    getTodayTask();
  }, []);

  const handleSearch = async (name) => {
    if (name) {
      const response = await searchTask(name, 2);
      if (response.data !== null) {
        setSearchedTasks(response?.data);
        setNoTask(false);
      } else {
        setSearchedTasks([]);
        setNoTask(true);
      }
    } else {
      setSearchedTasks([]);
      setNoTask(false);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);
    if (value === "") {
      setSearchedTasks([]);
      setNoTask(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(searchInput);
    }
  };

  const tasksToDisplay = searchedTasks?.length ? searchedTasks : taskToday;

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setSelectedImage(null);
  };

  return (
    <>
      {taskToday && (
        <>
          <Input
            width={{ base: "90%", md: "50%" }}
            margin="20px auto"
            display="block"
            value={searchInput}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            placeholder="Search tasks..."
            border={"1px solid gray"}
          />
          {!noTask ? (
            <Accordion
              allowMultiple
              padding="0 20px"
              maxWidth="1320px"
              margin="20px auto"
            >
              {tasksToDisplay.map((task, index) => {
                return (
                  <AccordionItem key={index} border="none">
                    <h2>
                      <AccordionButton
                        width="100%"
                        className="folder-bg-color"
                        margin="10px 0"
                        padding="15px"
                      >
                        <Box as="span" flex="1" textAlign="left">
                          {task.categoryName}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    {task.subCategories.map((subCategory, index) => {
                      return (
                        <AccordionPanel
                          overflowX={{ base: "scroll", sm: "auto" }}
                          key={index}
                        >
                          <Accordion
                            allowMultiple
                            width={{ base: "600px", md: "100%" }}
                          >
                            <AccordionItem
                              border="none"
                              width={{ base: "600px", md: "100%" }}
                            >
                              <AccordionButton
                                className="folder-bg-color"
                                display="flex"
                                // justifyContent="space-between"
                                alignItems="center"
                                padding="15px"
                              >
                                <Box
                                  width={{ base: "8%", md: "18%" }}
                                  display="flex"
                                  justifyContent="flex-start"
                                  visibility={"hidden"}
                                >
                                  <Text>{'#'}</Text>
                                </Box>
                                <Box
                                  width={{ base: "20%", md: "15%" }}
                                  display="flex"
                                  justifyContent="flex-start"
                                >
                                  <Text>Done</Text>
                                </Box>
                                <Box
                                  width={{ base: "30%", md: "30%" }}
                                  display="flex"
                                  justifyContent="flex-start"
                                >
                                  <Text>{subCategory.subCategoryName}</Text>
                                </Box>
                                <Box
                                  width={{ base: "30%", md: "25%" }}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <Text>Return</Text>
                                </Box>
                                <Box
                                  width={{ base: "10%", md: "8%" }}
                                  display="flex"
                                  justifyContent="flex-end"
                                >
                                  <Text>Attachment</Text>
                                </Box>
                              </AccordionButton>
                              {subCategory.tasks.map((title, index) => {
                                return (
                                  <AccordionPanel
                                    width="100%"
                                    display="flex"
                                    // justifyContent="space-between"
                                    key={title.id}
                                    // gap="10px"
                                    alignItems="center"
                                  >
                                    <Text fontWeight="600"
                                      width={{ base: "10%", md: "19%" }}
                                    >
                                      {index + 1}
                                    </Text>
                                    <Box
                                      marginTop="5px"
                                      width={{ base: "18%", md: "14%" }}
                                      display="flex-start"
                                    >
                                      <Checkbox
                                        value={title.isCompleted}
                                        onChange={() =>
                                          handleDone(
                                            title.isCompleted,
                                            title.id,
                                            title.name,
                                            title.image
                                          )
                                        }
                                        isChecked={title.isCompleted}
                                      />
                                    </Box>
                                    <Box
                                      marginTop="5px"
                                      width={{ base: "42%", md: "42%" }}
                                      display="flex"
                                      paddingRight={{ base: "15px", md: "5px" }}
                                    >
                                      <Text>{title.name}</Text>
                                    </Box>
                                    <Box
                                      marginTop="5px"
                                      marginLeft={{ base: "5px", md: "0px" }}
                                      width={{ base: "10%", md: "25%" }}
                                      display="flex"
                                    >
                                      <Checkbox
                                        value={title.isMarkedToday}
                                        onChange={() =>
                                          handleReturn(
                                            title.isMarkedToday,
                                            title.id,
                                            title.name,
                                            title.image
                                          )
                                        }
                                      />
                                    </Box>
                                    <Box
                                      marginTop="5px"
                                      // width="10%"
                                      display="flex"
                                    // justifyContent={{
                                    //   base: "center",
                                    //   sm: "flex-end",
                                    // }}
                                    // marginRight={{ base: "0", sm: "15px" }}
                                    >
                                      {title.image && (
                                        <Image
                                          src={title.image}
                                          // width="30px"
                                          height="30px"
                                          borderRadius="50%"
                                          onClick={() =>
                                            handleImageClick(title.image)
                                          }
                                          cursor="pointer"
                                        />
                                      )}
                                    </Box>
                                  </AccordionPanel>
                                );
                              })}
                            </AccordionItem>
                          </Accordion>
                        </AccordionPanel>
                      );
                    })}
                  </AccordionItem>
                );
              })}
            </Accordion>
          ) : (
            <Text textAlign="center" margin="20px 0">
              No Tasks Found
            </Text>
          )}
        </>
      )}
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedImage && <Image src={selectedImage} width="100%" />}
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
