import React, { useEffect, useRef, useState } from "react";

import {
  searchTask,
  taskReorder,
  todayTask,
  updateFolderName,
  updateSubFolderName,
  updateTaskName,
} from "../../api/api_functions";

import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { MdDragIndicator } from "react-icons/md";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Checkbox,
  Image,
  Input,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea,
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";

export default function AllTasks({ getTasks, tasks, getCategories }) {
  const [draggedTasks, setDraggedTasks] = useState(tasks);
  const [searchedTasks, setSearchedTasks] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [noTask, setNoTask] = useState(false);
  const [editable, setEditable] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setDraggedTasks(tasks);
  }, [tasks]);

  useEffect(() => {
    getTasks();
  }, []);

  const resizeTextArea = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const textAreaRef = useRef(null);

  const {
    register,
  } = useForm();

  const handleCheckBox = async (isMark, id, name, image) => {
    const response = await todayTask(isMark, id, name, image);
    getTasks();
  };

  const handleUpdateFolderName = async (folderId, name) => {
    if (!name) {
      toast.error("The Folder name can't be empty");
      return;
    }
    setEditable("");
    await updateFolderName(folderId, name);
    getTasks();
    getCategories();
  };

  const handleUpdateSubFolderName = async (folderId, subFolderId, name) => {
    if (!name) {
      toast.error("The Sub Folder name can't be empty");
      return;
    }
    setEditable("");
    await updateSubFolderName(folderId, subFolderId, name);
    getTasks();
  };

  const handleUpdateTaskName = async (taskId, name, image) => {
    if (!name) {
      toast.error("Task Title can't be empty");
    }
    setEditable("");
    await updateTaskName(taskId, name, image);
    getTasks();
  };

  const handleDragEnd = async (result) => {
    const taskId = result.draggableId;
    const newSequenceNumber = result.destination.index;
    await taskReorder(taskId, newSequenceNumber);
    getTasks();
  };

  const handleSearch = async (name) => {
    if (name) {
      const response = await searchTask(name);
      if (response.data !== null) {
        setSearchedTasks(response?.data);
        setNoTask(false);
      } else {
        setSearchedTasks([]);
        setNoTask(true);
      }
    } else {
      setSearchedTasks([]);
      setNoTask(false);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);
    if (value === "") {
      setSearchedTasks([]);
      setNoTask(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(searchInput);
    }
  };

  const tasksToDisplay = searchedTasks?.length ? searchedTasks : draggedTasks;

  const editField = (id) => {
    setEditable(id);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setSelectedImage(null);
  };

  return (
    <>
      {draggedTasks && (
        <>
          <Input
            width={{ base: "90%", md: "50%" }}
            margin="20px auto"
            display="block"
            value={searchInput}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            placeholder="Search tasks..."
            border={"1px solid gray"}
          />
          {!noTask ? (
            <Accordion
              maxWidth="1320px"
              margin="20px auto"
              padding="0 20px"
              allowMultiple
            >
              {tasksToDisplay.map((task) => {
                return (
                  <AccordionItem key={task.categoryId} border="none">
                    <h2>
                      <AccordionButton
                        className="folder-bg-color"
                        border="none"
                        width="100%"
                        margin="10px 0"
                      >
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          display="flex"
                          gap="5px"
                          alignItems="center"
                          key={task.id}
                        >
                          <FaEdit
                            cursor="pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              editField(task.categoryId);
                            }}
                          />
                          {editable == task.categoryId ? (
                            <Input
                              borderBottom="none"
                              variant="flushed"
                              fontWeight="600"
                              width="60%"
                              defaultValue={task.categoryName
                                .replace(/\s+/g, " ")
                                .trim()}
                              onBlur={(nextValue) => {
                                handleUpdateFolderName(
                                  task.categoryId,
                                  nextValue.target.value
                                );
                              }}
                            />
                          ) : (
                            <Text width="60%">{task.categoryName}</Text>
                          )}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    {task.subCategories.map((subCategory) => {
                      return (
                        <AccordionPanel
                          border="none"
                          pb={4}
                          overflowX={{ base: "scroll", md: "hidden" }}
                          key={subCategory?.subCategoryId}
                        >
                          <Accordion allowMultiple>
                            <AccordionItem
                              border="none"
                              width={{ base: "600px", md: "100%" }}
                            >
                              <AccordionButton
                                className="sub-folder-bg-color"
                                border="none"
                                width={{ base: "600px", md: "100%" }}
                              >
                                <Box
                                  as="span"
                                  width="100%"
                                  textAlign="left"
                                  display="flex"
                                  alignItems="center"
                                  gap="10px"
                                >
                                  <FaEdit
                                    cursor="pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      editField(subCategory.subCategoryId);
                                    }}
                                  />
                                  {editable == subCategory.subCategoryId ? (
                                    <Input
                                      border="none"
                                      fontWeight="600"
                                      flex={{
                                        base: "1",
                                        sm: "auto",
                                      }}
                                      width={{
                                        base: "50%",
                                        sm: "65%",
                                      }}
                                      defaultValue={subCategory.subCategoryName
                                        .replace(/\s+/g, " ")
                                        .trim()}
                                      onBlur={(nextValue) => {
                                        handleUpdateSubFolderName(
                                          task.categoryId,
                                          subCategory.subCategoryId,
                                          nextValue.target.value
                                        );
                                      }}
                                    />
                                  ) : (
                                    <Text
                                      textAlign="center"
                                      fontSize="14px"
                                      fontWeight="600"
                                      width={{
                                        base: "125px",
                                        sm: "30%",
                                        md: "26%",
                                      }}
                                    >
                                      Today
                                    </Text>
                                  )}
                                  <Text
                                    flex={{
                                      base: "1",
                                      sm: "auto",
                                    }}
                                    fontWeight="600"
                                    width={{
                                      base: "50%",
                                      sm: "65%",
                                    }}
                                  >
                                    {subCategory.subCategoryName}
                                  </Text>
                                  <Text
                                    textAlign="center"
                                    fontSize="14px"
                                    fontWeight="600"
                                    width={{
                                      base: "100px",
                                      sm: "30%",
                                      md: "20%",
                                    }}
                                  >
                                    Attachment
                                  </Text>
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                              <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable
                                  droppableId={subCategory.subCategoryId}
                                >
                                  {(provided) => (
                                    <Box
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {subCategory.tasks.map(
                                        (addedTask, index) => {
                                          return (
                                            <Draggable
                                              key={addedTask.id}
                                              draggableId={addedTask.id}
                                              index={addedTask.sequenceNumber}
                                            >
                                              {(provided) => (
                                                <div
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  ref={provided.innerRef}
                                                >
                                                  <AccordionPanel
                                                    borderBottom="1px solid #EDEDED"
                                                    width={{
                                                      base: "600px",
                                                      sm: "100%",
                                                    }}
                                                  >
                                                    <Box
                                                      display="flex"
                                                      alignItems="center"
                                                      marginTop="10px"
                                                      width="100%"
                                                      gap="10px"
                                                    >
                                                      <MdDragIndicator cursor="grab" />
                                                      <Text fontWeight="600">
                                                        {index + 1}
                                                      </Text>
                                                      <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        height={'auto'}
                                                        gap="5px"
                                                        width={{
                                                          base: "100%",
                                                          sm: "55%",
                                                        }}
                                                      >
                                                        {editable ==
                                                          addedTask.id ? (
                                                          <Textarea
                                                            resize="none"
                                                            minHeight="40px !important"
                                                            variant="flushed"
                                                            ref={textAreaRef}
                                                            height={'auto'}
                                                            id="task-title"
                                                            {...register("taskTitle")}
                                                            style={{ width: "100%", resize: "none", overflow: "hidden" }}
                                                            onChange={(e) => resizeTextArea(e.target)}
                                                            defaultValue={addedTask.name
                                                              .replace(
                                                                /\s+/g,
                                                                " "
                                                              )
                                                              .trim()}
                                                            onBlur={(e) => {
                                                              handleUpdateTaskName(
                                                                addedTask.id,
                                                                e.target.value,
                                                                addedTask.image
                                                              );
                                                            }}
                                                          />
                                                        ) : (
                                                          <>
                                                            <Box
                                                              width={{
                                                                base: "125px",
                                                                sm: "20%",
                                                                md: "60%",
                                                              }}
                                                              display="flex"
                                                              justifyContent={{
                                                                base: "center",
                                                                sm: "center",
                                                                md: "center",
                                                              }}
                                                            >
                                                              <Checkbox
                                                                value={
                                                                  addedTask.isMarkedToday
                                                                }
                                                                onChange={() =>
                                                                  handleCheckBox(
                                                                    addedTask.isMarkedToday,
                                                                    addedTask.id,
                                                                    addedTask.name,
                                                                    addedTask.image
                                                                  )
                                                                }
                                                                isChecked={
                                                                  addedTask.isMarkedToday
                                                                }
                                                              />

                                                            </Box>
                                                            <Box 
                                                              width={{
                                                                base: "100%",
                                                                md: "110%"
                                                              }}
                                                              display="flex"
                                                              alignItems="center"
                                                              gap="5px"
                                                              >

                                                            <Icon
                                                              as={FaEdit}
                                                              fontSize="12px"
                                                              cursor="pointer"
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                editField(
                                                                  addedTask.id
                                                                );
                                                              }}
                                                            />
                                                            <Text
                                                              ref={textAreaRef}
                                                              height={'auto'}
                                                              readOnly={true}
                                                              id="task-title"
                                                              {...register("taskTitle")}
                                                              style={{ width: "100%", resize: "none", overflow: "hidden" }}
                                                              onChange={(e) => resizeTextArea(e.target)}
                                                            >
                                                              {addedTask.name}
                                                            </Text>
                                                              </Box>
                                                          </>
                                                        )}
                                                      </Box>
                                                      <Box
                                                        width={{
                                                          base: "150px",
                                                          md: "0%",
                                                        }}
                                                        display="flex"
                                                        justifyContent={{
                                                          base: "flex-start",
                                                          sm: "flex-start",
                                                          md: "center",
                                                        }}
                                                      >
                                                        {addedTask.image && (
                                                          <Image
                                                            width="30px"
                                                            height="30px"
                                                            borderRadius="50%"
                                                            src={
                                                              addedTask.image
                                                            }
                                                            onClick={() =>
                                                              handleImageClick(
                                                                addedTask.image
                                                              )
                                                            }
                                                            alt="Image"
                                                          />
                                                        )}
                                                      </Box>
                                                    </Box>
                                                  </AccordionPanel>
                                                </div>
                                              )}
                                            </Draggable>
                                          );
                                        }
                                      )}
                                      {provided.placeholder}
                                    </Box>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </AccordionItem>
                          </Accordion>
                        </AccordionPanel>
                      );
                    })}
                  </AccordionItem>
                );
              })}
            </Accordion>
          ) : (
            <Text textAlign="center" margin="20px 0">
              No Tasks Found
            </Text>
          )}
        </>
      )}
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedImage && <Image src={selectedImage} width="100%" />}
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
